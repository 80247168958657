

export const API = {
  VERSION: '1.1.5',
  URL: 'https://api.publit.io/v1'
}

export const ACTIONS = {
  FILE: 'file',
  WATERMARK: 'watermark'
}

export const MIN = 0
export const MAX = 99999999
